<!-- 营销中心 商户营销工具 商户卡券管理 优惠券管理 优惠券明细 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="handlePageButtonClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>

    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="`${this.couponName}发放明细`"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              v-rbac="'marketing:mcard:coupon:dataexport'"
              type="plain"
              @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="total"
          :current-page.sync="currentPage"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageSize"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { marketManage } from '@/service/apis';
  import { code, sessionGetItem, offlineExport } from '@/utils';

  export default {
    name: 'merchantCouponParticulars',
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        searchData: [
          {
            comName: 'YkcInput',
            key: 'userCardCouponId',
            label: '优惠券编号',
            placeholder: '请输入优惠券编号',
          },
          {
            comName: 'YkcDropdown',
            key: 'usedStatus',
            label: '使用状态',
            placeholder: '请选择使用状态',
            data: [
              { id: '0', name: '未使用' },
              { id: '1', name: '使用中' },
              { id: '2', name: '已使用' },
              { id: '3', name: '已过期' },
              { id: '4', name: '已作废' },
            ],
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '领取用户',
            placeholder: '请输入领取用户',
          },
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '归属客户',
            placeholder: '请输入归属客户',
          },
          {
            comName: 'YkcDatePicker',
            type: 'daterange',
            format: 'yyyy-MM-dd',
            key: 'firstUseTime',
            label: '使用时间',
          },
          {
            comName: 'YkcDatePicker',
            type: 'daterange',
            format: 'yyyy-MM-dd',
            key: 'issueTime',
            label: '发放时间',
          },
          {
            comName: 'YkcInput',
            key: 'activityName',
            label: '关联活动',
            placeholder: '请输入关联活动',
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '优惠券编号', prop: 'userCardCouponId', minWidth: '100px' },
          {
            label: '使用状态',
            prop: 'usedStatus',
            minWidth: '80px',
            scopedSlots: {
              default: ({ row }) => {
                const usedStatusMap = {
                  0: '未使用',
                  1: '使用中',
                  2: '已使用',
                  3: '已过期',
                  4: '已作废',
                };
                const usedStatus = usedStatusMap[row.usedStatus] || '——';
                return (
                  <div>
                    <span title={usedStatus}>{usedStatus}</span>
                  </div>
                );
              },
            },
          },
          { label: '领取用户', prop: 'userAccount', minWidth: '100px' },
          { label: '归属客户', prop: 'orgName', minWidth: '120px' },
          { label: '关联活动', prop: 'activityName', minWidth: '120px' },
          { label: '发放时间', prop: 'issueTime', minWidth: '140px' },
          { label: '使用时间', prop: 'firstUseTime', minWidth: '140px' },
        ],

        total: 0,
        currentPage: 1,
        pageSize: 10,
        searchParams: {
          userCardCouponId: '',
          usedStatus: '',
          userAccount: '',
          orgName: '',
          firstUseTime: [],
          firstUseStartTime: '',
          firstUseEndTime: '',
          issueTime: [],
          issueStartTime: '',
          issueEndTime: '',
          activityName: '',
          cardCouponId: '',
        },
        tableOperateButtons: [
          {
            enabled: () => code('marketing:mcard:coupon:datacancel'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.usedStatus !== '0'}
                onClick={() => {
                  this.openCancel(row);
                }}>
                作废
              </ykc-button>
            ),
          },
          {
            enabled: () => code('URL:order:charge:his'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={!['1', '2'].includes(row.usedStatus)}
                onClick={() => {
                  console.log('查看订单', row);
                  this.handleCellClick(row);
                }}>
                查看订单
              </ykc-button>
            ),
          },
        ],
        cardCouponId: '',
      };
    },
    computed: {},
    created() {
      const query = JSON.parse(this.$route.query.data);
      this.cardCouponId = query.cardCouponId;
      this.couponName = query.cardCouponName;
      this.searchParams.cardCouponId = query.cardCouponId;
      this.getData();
    },
    methods: {
      // 作废
      openCancel(row) {
        console.log('作废', row);
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          title: '',
          confirmBtnTxt: '确认',
          desc: '确认作废',
          onConfirm: done => {
            marketManage
              .detailCancel({
                userCardCouponId: row.userCardCouponId,
                operatorBy: sessionGetItem('accountNo'),
                cardCouponType: '2',
                undertakerType: '2',
              })
              .then(res => {
                console.log(res);
                this.$message({
                  message: '作废成功',
                  type: 'success',
                });
                this.getData();
              })
              .catch(err => {
                this.$message({
                  message: err.data?.resultDesc,
                  type: 'error',
                });
              });
            done();
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
        console.log('列表按钮点击事件', button, event);
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.currentPage = 1;
        if (form.firstUseTime && form.firstUseTime.length !== 0) {
          const firstUseStartTime = form.firstUseTime ? form.firstUseTime[0] : '';
          const firstUseEndTime = form.firstUseTime ? form.firstUseTime[1] : '';
          form.firstUseStartTime = `${firstUseStartTime}`;
          form.firstUseEndTime = `${firstUseEndTime}`;
        }
        if (form.firstUseTime == null) {
          form.firstUseStartTime = '';
          form.firstUseEndTime = '';
        }
        if (form.issueTime && form.issueTime.length !== 0) {
          const issueStartTime = form.issueTime ? form.issueTime[0] : '';
          const issueEndTime = form.issueTime ? form.issueTime[1] : '';
          form.issueStartTime = `${issueStartTime}`;
          form.issueEndTime = `${issueEndTime}`;
        }
        if (form.issueTime == null) {
          form.issueStartTime = '';
          form.issueEndTime = '';
        }
        Object.assign(this.searchParams, form);
        this.getData();
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.currentPage = 1;
        this.pageSize = size;
        this.getData();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        this.searchParams.cardCouponId = this.cardCouponId;
      },
      /**
       * @desc 获取远程数据
       * */
      getData() {
        marketManage
          .detailPage({
            ...this.searchParams,
            cardCouponType: '2',
            undertakerType: '2',
            current: this.currentPage,
            size: this.pageSize,
          })
          .then(res => {
            console.log(res);
            this.tableData = res?.records || [];
            this.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.total = 0;
          });
      },
      /**
       * 导出列表
       */
      doExportFile() {
        console.log('导出列表');
        let [firstUseStartTime, firstUseEndTime] = ['', ''];
        if (this.searchParams.firstUseTime.length > 0) {
          [firstUseStartTime, firstUseEndTime] = this.searchParams.firstUseTime;
        }
        let [issueStartTime, issueEndTime] = ['', ''];
        if (this.searchParams.issueTime.length > 0) {
          [issueStartTime, issueEndTime] = this.searchParams.issueTime;
        }
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'user_card_coupon_export',
              cardCouponId: this.searchParams.cardCouponId,
              userCardCouponId: this.searchParams.userCardCouponId || '',
              usedStatus: this.searchParams.usedStatus || '',
              userAccount: this.searchParams.userAccount || '',
              orgName: this.searchParams.orgName || '',
              activityName: this.searchParams.activityName || '',
              firstUseStartTime,
              firstUseEndTime,
              issueStartTime,
              issueEndTime,
              cardCouponType: '2',
              undertakerType: '2',
            },
          },
          this.total
        );
      },
      /**
       * 跳转到订单
       */
      handleCellClick(row) {
        const routeUrl = this.$router.resolve({
          path: '/orderManagement/historicalOrder/List',
          query: {
            data: JSON.stringify(row),
          },
        });
        window.open(routeUrl.href, '_blank');
      },
    },
  };
</script>
<style lang="scss" scoped></style>
