<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/商户卡券活动/新增或者编辑(充电)
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="活动名称" prop="activityName">
      <ykc-input
        v-model="ruleForm.activityName"
        placeholder="请输入活动名称"
        maxlength="50"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="承担商户" prop="activityInitiatorId">
      <ykc-dropdown
        placeholder="请输入商户名称"
        :configSet="configSet"
        :data="undertakerList"
        @change="undertakerChange"
        remote
        v-model="ruleForm.activityInitiatorId"></ykc-dropdown>
    </ykc-form-item>
    <ykc-form-item label="活动时间" prop="activityTime">
      <ykc-date-picker limitDate="limitBefore" v-model="ruleForm.activityTime"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item label="活动说明" prop="activityRemark">
      <ykc-input
        v-model="ruleForm.activityRemark"
        type="textarea"
        placeholder="请输入活动说明"
        maxlength="500"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="发放条件" prop="limitPower">
      <ykc-input v-model="ruleForm.limitPower" placeholder="请输入发放条件" maxlength="8">
        <template slot="prepend">充电满</template>
        <template slot="append">度发放</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="卡券设置" class="required">
      <ykc-combination-item
        required
        :basicData="basicData"
        :selectData="coupons"
        :data="setData"
        :maxlength="20"
        addText="添加优惠券/抵用卡"
        ref="combination"></ykc-combination-item>
    </ykc-form-item>
    <div class="limited-wrap">
      <ykc-form-item label="限制次数" prop="limitCount">
        <ykc-input
          v-model="ruleForm.limitCount"
          :maxlength="8"
          placeholder="请输入次数"></ykc-input>
      </ykc-form-item>
      <ykc-dropdown
        placeholder="请选择"
        :clearable="false"
        :data="LIMITED_TYPE"
        v-model="ruleForm.limitType"></ykc-dropdown>
    </div>
    <ykc-form-item prop="stationList" label="选择电站">
      <ykc-tree
        ref="stationTree"
        :data="stationData"
        :props="stationProps"
        @check-change="handleStationTree"></ykc-tree>
    </ykc-form-item>
    <ykc-form-item prop="userList" label="适用用户范围设置">
      <ykc-tree
        ref="treeUser"
        :data="usersData"
        :props="userProps"
        @check-change="handleUsersTree" />
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import { couponActivity, marketManage, operatorData, treeUtils } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    props: {
      editModel: {
        type: String,
        default: 'add', // add edit copy
      },
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        LIMITED_TYPE: [
          {
            id: '1',
            name: '次/人/天',
          },
          {
            id: '2',
            name: '次/人/活动周期',
          },
        ],
        coupons: [],
        setData: [],
        selectData: [],
        basicData: [
          {
            comName: 'YkcDropdown',
            key: 'couponId',
            remote: true,
            value: 'name',
            placeholder: '请选择优惠券/抵用卡',
            rules: { required: true, message: '请选择优惠券/抵用卡', trigger: 'blur' },
            prepend: '卡券',
            only: true,
            data: [],
          },
          {
            comName: 'YkcInput',
            value: '',
            key: 'issueCount',
            maxlength: 8,
            prepend: '数量',
            append: '张/人',
            placeholder: '请输入卡券数量',
            rules: [
              { required: true, message: '请输入卡券数量', trigger: 'blur' },
              {
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                  '卡券数量',
                  99999999
                ),
                trigger: 'blur',
              },
              { validator: this.checkCouponNumber, trigger: 'blur' },
            ],
          },
        ],
        configSet: {
          label: 'name',
          value: 'operatorId',
        },
        undertakerList: [], // 承担商户列表
        stationData: [],
        usersData: [],
        userGroupData: [],
        userProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        stationsData: [],
        stationGroupData: [],
        stationProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        ruleForm: {
          activityName: '',
          activityTime: [],
          activityRemark: '',
          limitType: '1',
          limitCount: '',
          limitPower: '',
          limitedType: '',
          userList: [],
          stationList: [],
          activityInitiatorId: '',
        },
        rules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动名称'),
              trigger: 'blur',
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          activityTime: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          activityRemark: [
            { required: false, trigger: 'blur', message: '请输入活动说明' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('活动说明'),
              trigger: 'blur',
            },
          ],
          limitPower: [
            { required: true, trigger: 'blur', message: '请输入发放条件' },
            {
              pattern: regexpObj.regexp.input.priceNumberType.regexp,
              message: regexpObj.regexp.input.priceNumberType.errorTips.error('发放条件', 999.9999),
              trigger: 'blur',
            },
          ],
          limitCount: [
            { required: true, trigger: 'blur', message: '请输入设置次数' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '设置次数',
                99999999
              ),
              trigger: 'blur',
            },
          ],
          activityInitiatorId: [{ required: true, message: '请输入商户名称', trigger: 'blur' }],
          stationList: [{ required: true, message: '请选择电站', trigger: 'change' }],
          userList: [{ required: true, message: '请选择用户', trigger: 'change' }],
        },
      };
    },
    computed: {},
    created() {
      this.getBaseInfo();
    },
    methods: {
      /**
       * 商户选择变化
       */
      undertakerChange(res) {
        console.log(res);
        this.resetData();
        if (res) {
          this.updateTreeAndCoupons();
        }
      },
      resetData() {
        this.coupons = [];
        this.setData = [];
        this.ruleForm.userList = [];
        this.ruleForm.stationList = [];
      },
      getBaseInfo() {
        const operatorsRequest = operatorData.userOperatorList({ name: '' }); // 商户列表
        const requests = [operatorsRequest];
        // 详情
        if (this.activityId && (this.editModel === 'edit' || this.editModel === 'copy')) {
          const requestDetail = couponActivity.chargeActivityDetail({
            activityId: this.activityId,
          });
          requests.push(requestDetail);
        }
        Promise.all(requests).then(([operatorsRes, resDetail]) => {
          const operators = operatorsRes || [];
          operators.forEach(item => {
            item.operatorId = `${item.operatorId}`;
          });
          this.undertakerList = operators;
          this.detail = resDetail || {};
          if (this.activityId) {
            this.fillInitData();
          }
        });
      },

      fillInitData() {
        if (this.editModel === 'edit') {
          this.ruleForm.activityName = this.detail.activityName;
          this.ruleForm.activityTime = [];
          if (this.detail.activityBeginTime) {
            // 这里有问题 编辑没有返回时间
            this.ruleForm.activityTime.push(this.detail.activityBeginTime);
          }
          if (this.detail.activityEndTime) {
            this.ruleForm.activityTime.push(this.detail.activityEndTime);
          }
        }
        this.ruleForm.activityRemark = this.detail.activityRemark || '';
        this.ruleForm.limitType = this.detail.limitType;
        this.ruleForm.limitCount = this.detail.limitCount;
        this.ruleForm.limitPower = this.detail.limitPower;
        // 用户维度
        if (this.activityId) {
          this.updateTreeAndCoupons();
        }
      },
      /**
       * 更新树和优惠券信息
       */
      updateTreeAndCoupons() {
        const operatorId = this.ruleForm.activityInitiatorId || this.detail.activityInitiatorId; // 选择商户sessionGetItem('accountId') || '8';
        const userTreeRequest = treeUtils.operatorUsers({ operatorId });
        const stationTreeRequest = treeUtils.operatorStations({
          idList: [Number(operatorId || '0')],
        });

        const requestCoupons = marketManage.activityList({
          useType: '1', // 使用类型（1:普通，2:会员）
          undertakerType: '2', // 承担方类型（1:平台，2:商户）
          undertakerId: operatorId, // 承担商户id
        });
        const requests = [userTreeRequest, stationTreeRequest, requestCoupons];
        Promise.all(requests).then(([usersData, stationRes, resCoupon]) => {
          this.formatUserTreeData(usersData);
          this.formatStationTreeData(stationRes);
          // 选择的是默认
          this.selectedTreesNodes(); // 填充选择内容
          // 优惠券
          if (resCoupon) {
            this.coupons = [];
            if (
              this.activityId &&
              (this.detail.activityStatus === '1' || this.detail.activityStatus === '2')
            ) {
              // 只有未开始进行中，进行复制卡券{
              this.detail.rewardList.forEach(item => {
                this.coupons.push({
                  cardCouponId: item.rewardId,
                  id: item.rewardId,
                  name: item.rewardName,
                  cardCouponType: item.rewardType,
                  leftCount: item.rewardCount,
                });
              });
            }
            const coupons = resCoupon.map(item => {
              // key: 'cardCouponId',
              // name: 'cardCouponName',
              const { cardCouponId, cardCouponName, remainderNum } = item;
              item.id = cardCouponId;
              item.name = cardCouponName;
              item.leftCount = remainderNum;
              return item;
            });
            this.coupons.push(...coupons);
            if (this.activityId) {
              this.$nextTick(() => {
                this.setData = this.formatSetData();
              });
            }
          }
        });
      },
      selectedTreesNodes() {
        if (this.detail?.activityInitiatorId && !this.ruleForm.activityInitiatorId) {
          // 第一次加载的时候回显
          this.ruleForm.activityInitiatorId = this.detail.activityInitiatorId;
          // 用户
          this.ruleForm.userList = this.fillTreeSelectedIds(this.detail.userScopeList);
          if (this.ruleForm.userList.length > 0) {
            this.$nextTick(() => {
              this.$refs.treeUser.setCheckedKeys(this.ruleForm.userList, true);
            });
          }
          // 电站
          this.ruleForm.stationList = this.fillTreeSelectedIds(this.detail.stationScopeList);
          if (this.ruleForm.stationList.length > 0) {
            this.$nextTick(() => {
              this.$refs.stationTree.setCheckedKeys(this.ruleForm.stationList, true);
            });
          }
        }
      },
      formatUserTreeData(data) {
        if (data) {
          this.usersData = [];
          if (data) {
            let id = 1000000;
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, users] of Object.entries(data)) {
              console.log(key, users);
              const user = {
                id: id++,
                name: key,
                subNodes: [],
                disabled: false,
              };
              users.forEach(item => {
                user.subNodes.push({
                  id: item.id,
                  name: item.name,
                });
              });
              this.usersData.push(user);
            }
          }
        }
      },
      formatUserGroupTreeData(data) {
        this.userGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const userGroup = {
              id: item.id,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.userGroupData.push(userGroup);
          });
        }
      },
      formatStationTreeData(data) {
        this.stationData = [];

        if (data?.length > 0) {
          const item = data[0];
          if (item && item?.stationTreeVOList?.length > 0) {
            if (item?.stationTreeVOList?.length > 0) {
              item.stationTreeVOList.forEach(item1 => {
                const station = {
                  id: item1.stationId,
                  name: item1.stationName || '未知',
                  disabled: false,
                };
                this.stationData.push(station);
              });
            }
          }
        }
      },
      formatStationGroupTreeData(data) {
        this.stationGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const stationGroup = {
              id: item.id,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.stationGroupData.push(stationGroup);
          });
        }
      },
      fillTreeSelectedIds(records) {
        const selectedIds = [];
        records.forEach(item => {
          selectedIds.push(item.dataId);
        });
        return selectedIds;
      },
      formatSetData() {
        const selectedCoupons = [];
        this.detail.rewardList.forEach(item => {
          selectedCoupons.push({
            id: item.rewardId,
            couponId: item.rewardId,
            issueCount: item.rewardCount,
            name: item.rewardName,
            couponName: item.rewardName,
          });
        });
        return selectedCoupons;
      },

      submitForm() {
        return new Promise(resolve => {
          const couponValidForm = this.$refs.combination.getResult();
          const dataValidForm = this.validateAllForm();
          Promise.all([couponValidForm, dataValidForm]).then(([couponResult]) => {
            // 新增/复制/编辑
            if (couponResult.validState) {
              const submitData = this.formData();
              submitData.rewardList = this.formatCouponData(couponResult.data || []);
              couponActivity.chargeActivitySave(submitData).then(res => {
                console.log(res);
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      /**
       * 校验表单
       * @returns {Promise<unknown>}
       */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      formatCouponData(selectedCoupons) {
        const couponDatas = [];
        if (selectedCoupons.length > 0) {
          selectedCoupons.forEach(selectItem => {
            const selectId = selectItem.couponId;
            for (let i = 0; i < this.coupons.length; i++) {
              const originCoupon = this.coupons[i];
              if (originCoupon.cardCouponId === selectId) {
                couponDatas.push({
                  rewardType: originCoupon.cardCouponType,
                  rewardId: selectId,
                  rewardCount: selectItem.issueCount || '',
                });
                break;
              }
            }
          });
        }
        return couponDatas;
      },
      formData() {
        const startTime =
          this.ruleForm.activityTime[0].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[0]
            : `${this.ruleForm.activityTime[0]} 00:00:00`;
        const endTime =
          this.ruleForm.activityTime[1].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[1]
            : `${this.ruleForm.activityTime[1]} 00:00:00`;
        const formData = {
          activityType: '4',
          activityInitiator: '2',
          activityInitiatorId: this.ruleForm.activityInitiatorId,
          activityName: this.ruleForm.activityName,
          activityBeginTime: startTime,
          activityEndTime: endTime,
          activityRemark: this.ruleForm.activityRemark || '',
          limitPower: this.ruleForm.limitPower,
          limitCount: this.ruleForm.limitCount,
          limitType: this.ruleForm.limitType,
          userScope: '1',
          stationScope: '1',
          userScopeList: this.getTreeFormatSelectedDatas(this.$refs.treeUser),
          stationScopeList: this.getTreeFormatSelectedDatas(this.$refs.stationTree),
        };
        // 编辑/copy
        if (this.editModel === 'edit') {
          formData.activityId = this.activityId;
        }
        return formData;
      },
      getTreeFormatSelectedDatas(tree) {
        const selectedDatas = [];
        const items = tree.getCheckedNodes(true, false);
        items.forEach(item => {
          selectedDatas.push({
            dataId: item.id,
            dataName: item.name,
            orgType: item.orgType,
          });
        });
        return selectedDatas;
      },

      /**
       * 输入框数量校验
       */
      checkCouponNumber(rule, value, callback) {
        return callback();
      },
      handleUsersTree() {
        this.ruleForm.userList = this.$refs.treeUser.getCheckedKeys(true) || [];
      },
      handleStationTree() {
        this.ruleForm.stationList = this.$refs.stationTree.getCheckedKeys(true) || [];
      },
      /**
       * 活动名称校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          couponActivity
            .checkNameOnly({
              activityName: value,
              activityType: '4',
              activityId: this.editModel === 'edit' ? this.activityId : '',
            })
            .then(res => {
              if (res?.repeatFlag) {
                this.nameValidError = '活动名称已存在'; // res.resultDesc;
                callback(new Error(this.nameValidError));
                // eslint-disable-next-line no-dupe-else-if
              } else if (res?.repeatFlag === false) {
                // 校验通过
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
    },
  };
</script>
<style lang="scss">
  .drawer-main-body {
    .limited-wrap {
      display: flex;

      .ykc-form-item {
        flex: 1;
      }

      .ykc-dropdown {
        width: 150px;
        margin-left: 10px;
        margin-top: 26px;
      }
    }
  }
</style>
