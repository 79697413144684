<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-11
 * @Description: 营销管理/平台营销工具/商户价格活动/新增或者编辑
-->
<template>
  <ykc-form class="el-form-wrap">
    <el-steps :active="active" align-center finish-status="success">
      <el-step title="活动配置" />
      <el-step title="站点配置" />
      <el-step title="用户配置" />
    </el-steps>
    <div style="margin-top: 20px">
      <!-- 活动配置 -->
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm0" v-show="active === 0">
        <ykc-form-item label="活动名称" prop="activityName">
          <ykc-input v-model="ruleForm.activityName" placeholder="请输入活动名称" maxlength="50" />
        </ykc-form-item>
        <ykc-form-item label="承担商户" prop="undertaker">
          <ykc-dropdown
            placeholder="请输入商户名称"
            :configSet="configSet"
            :data="undertakerList"
            remote
            @change="undertakerChange"
            v-model="ruleForm.undertaker"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="活动时间" prop="activityDate">
          <ykc-date-picker limitDate="limitBefore" v-model="ruleForm.activityDate" />
        </ykc-form-item>
        <ykc-form-item label="活动周天" prop="selectWeeks">
          <ykc-checkbox-button
            :data="weeksData"
            v-model="ruleForm.selectWeeks"
            @change="weeksChange"
            :configSet="weekProps"
            :checkboxStyle="{ width: 87, height: 28 }"
            type="button" />
        </ykc-form-item>
        <ykc-form-item label="活动介绍">
          <ykc-input
            v-model="ruleForm.remarks"
            type="textarea"
            placeholder="请输入活动介绍（选填）"
            maxlength="500"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="电费优惠" prop="electricityDiscount">
          <div class="_line">
            <ykc-dropdown
              placeholder="请选择"
              :clearable="false"
              :data="[
                { id: '1', name: '一口价' },
                { id: '2', name: '折扣' },
              ]"
              v-model="ruleForm.powerRateType" />
            <ykc-input
              v-model="ruleForm.electricityDiscount"
              :maxlength="ruleForm.powerRateType === '2' ? 3 : 8"
              :placeholder="`请输入${ruleForm.powerRateType === '2' ? '折扣' : '一口价'}`">
              <template slot="append">
                {{ ruleForm.powerRateType === '2' ? '%' : '元/度' }}
              </template>
            </ykc-input>
          </div>
        </ykc-form-item>
        <ykc-form-item label="服务费优惠" prop="serviceDiscount">
          <div class="_line">
            <ykc-dropdown
              placeholder="请选择"
              :clearable="false"
              :data="[
                { id: '1', name: '一口价' },
                { id: '2', name: '折扣' },
              ]"
              v-model="ruleForm.serviceRateType" />
            <ykc-input
              v-model="ruleForm.serviceDiscount"
              :placeholder="`请输入${ruleForm.serviceRateType === '2' ? '折扣' : '一口价'}`"
              :maxlength="ruleForm.serviceRateType === '2' ? 3 : 8">
              <template slot="append">
                {{ ruleForm.serviceRateType === '2' ? '%' : '元/度' }}
              </template>
            </ykc-input>
          </div>
        </ykc-form-item>
      </ykc-form>
      <!-- 站点配置 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm1"
        class="el-form-wrap"
        v-show="active === 1">
        <ykc-form-item prop="stationList" label="选择电站">
          <ykc-tree
            ref="treeStation"
            :data="stationData"
            :props="stationProps"
            @check-change="handleStationTree" />
        </ykc-form-item>
      </ykc-form>
      <!-- 用户配置 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm2"
        class="el-form-wrap"
        v-show="active === 2">
        <ykc-form-item prop="userList" label="选择用户">
          <ykc-tree
            ref="treeUser"
            :data="usersData"
            :props="userProps"
            @check-change="handleUsersTree" />
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { filterByIdList } from '@/utils/index';
  import { orgMatchPushRule, merchantPriceActivity, treeUtils, operatorData } from '@/service/apis';

  export default {
    components: {},
    props: {
      active: {
        type: Number,
        default: 0,
      },
      editModel: {
        type: String,
        default: 'add', // add copy edit
      },
      id: {
        type: String,
      },
    },
    data() {
      return {
        activeId: '',
        value: [],
        weekProps: {
          label: 'label',
          value: 'value',
        },
        weeksData: [
          {
            value: '1',
            label: '星期一',
          },
          {
            value: '2',
            label: '星期二',
          },
          {
            value: '3',
            label: '星期三',
          },
          {
            value: '4',
            label: '星期四',
          },
          {
            value: '5',
            label: '星期五',
          },
          {
            value: '6',
            label: '星期六',
          },
          {
            value: '7',
            label: '星期日',
          },
        ],
        // 树数据
        stationData: [],
        stationGroupData: [],
        usersData: [],
        userGroupData: [],

        serviceFeeMap: {
          jian: '',
          feng: '',
          ping: '',
          gu: '',
        },
        electricityFeeMap: {
          jian: '',
          feng: '',
          ping: '',
          gu: '',
        },

        pictureArr: [
          // 电站图片
          {
            name: '主入口图',
            url: '',
          },
          {
            name: '标志路径',
            url: '',
          },
          {
            name: '电站全景',
            url: '',
          },
          {
            name: '电桩特写',
            url: '',
          },
          {
            name: '其他图片',
            url: '',
          },
        ],
        configSet: {
          label: 'name',
          value: 'operatorId',
        },
        undertakerList: [], // 承担商户列表
        stationProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        userProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },

        cityStationData: [],
        setData: [],
        fleetOrg: [],
        operatorProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },

        cityProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        ruleForm: {
          // step1
          activityName: '', // 活动名称
          undertaker: '', // 新增商户
          activityDate: [], // 活动日期
          remarks: '', // 活动描述
          selectWeeks: ['1', '2', '3', '4', '5', '6', '7'], // 周天
          electricityDiscount: '', // 电费优惠折扣
          serviceDiscount: '', // 服务费优惠 //折扣
          powerRateType: '1',
          serviceRateType: '1',
          // step2
          stationRange: '1', // 电站维度
          stationList: [],
          stationGroupList: [], // 电站分组

          // step3
          userRange: '1', // 用户维度
          userList: [],
        },
        /*  rules: {

      }, */
        // 详情数据
        detail: {},
        dictionary: {},
        organizations: [],
        originActivityName: '', // 原来名称
      };
    },
    created() {
      // 获取树
      this.getBaseInfo();
    },
    computed: {
      /**
       * 车队列表默认展示符
       * */
      organizationPlaceHolder() {
        if (this.organizations.length) {
          return '请输入归属车队';
        }
        return '暂无数据';
      },
      rules() {
        const ruleCreate = {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动名称'),
              trigger: 'blur',
            },
            { validator: this.onlyCheckRuleName, trigger: 'blur' },
          ],
          undertaker: [{ required: true, message: '请输入商户名称', trigger: 'blur' }],
          activityDate: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          selectWeeks: [{ required: true, trigger: 'change', message: '请选择活动周天' }],
          remarks: [
            { required: false, trigger: 'blur', message: '请输入活动介绍' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('活动介绍'),
              trigger: 'blur',
            },
          ],

          // step2
          stationList: [
            { required: true, type: 'array', message: '请选择电站', trigger: 'change' },
          ],
          stationGroupList: [
            { required: true, type: 'array', message: '请选择电站分组', trigger: 'change' },
          ],
          // step3
          userList: [{ required: true, type: 'array', message: '请选择用户', trigger: 'change' }],
        };
        let powerRules;
        if (this.ruleForm?.powerRateType === '2') {
          powerRules = [
            { required: true, trigger: 'blur', message: '请输入电费优惠' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error('电费折扣', 100),
              trigger: 'blur',
            },
            { validator: this.checkElectricityDiscount, trigger: 'blur' },
          ];
        } else {
          powerRules = [
            { required: true, trigger: 'blur', message: '请输入电费优惠' },
            {
              pattern: regexpObj.regexp.input.priceNumberType.regexp,
              message: regexpObj.regexp.input.priceNumberType.errorTips.error('电费优惠', 999.9999),
              trigger: 'blur',
            },
            { validator: this.checkElectricityDiscount, trigger: 'blur' },
          ];
        }
        let serviceRules;
        if (this.ruleForm?.serviceRateType === '2') {
          serviceRules = [
            { required: true, trigger: 'blur', message: '请输入服务费优惠' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error('服务费折扣', 100),
              trigger: 'blur',
            },
            { validator: this.checkServiceDiscount, trigger: 'blur' },
          ];
        } else {
          serviceRules = [
            { required: true, trigger: 'blur', message: '请输入服务费优惠' },
            {
              pattern: regexpObj.regexp.input.priceNumberType.regexp,
              message: regexpObj.regexp.input.priceNumberType.errorTips.error(
                '服务费优惠',
                999.9999
              ),
              trigger: 'blur',
            },
            { validator: this.checkServiceDiscount, trigger: 'blur' },
          ];
        }

        return {
          ...ruleCreate,
          electricityDiscount: powerRules,
          serviceDiscount: serviceRules,
        };
      },
      formData() {
        const formData = {
          activityName: this.ruleForm.activityName,
          undertaker: this.ruleForm.undertaker,
          startTime: this.ruleForm.activityDate[0] || '',
          endTime: this.ruleForm.activityDate[1] || '',
          //  activityType: '2',
          activityWeek: this.ruleForm.selectWeeks?.join(',') || '',
          remarks: this.ruleForm.remarks || '',
          powerRateType: this.ruleForm.powerRateType,
          powerRate: this.ruleForm.electricityDiscount || '',
          serviceRateType: this.ruleForm.serviceRateType,
          serviceRate: this.ruleForm.serviceDiscount || '',
          stationRange: '1',
          stationIds: this.ruleForm.stationList,
          userRange: '1',
          userIds: this.ruleForm.userList,
        };
        // 编辑/copy
        if (this.editModel === 'edit') {
          formData.activityId = this.id;
        }
        return formData;
      },
    },
    methods: {
      handlePowerTypeChange() {
        this.$nextTick(() => {
          this.ruleForm.electricityDiscount = '';
        });
      },
      handleServiceTypeChange() {
        this.$nextTick(() => {
          this.ruleForm.serviceDiscount = '';
        });
      },
      /**
       * 商户选择变化
       */
      undertakerChange(res) {
        console.log(res);
        // this.ruleForm.undertaker = res;
        this.updateTrees();
      },
      /**
       * 更新树数据
       */
      updateTrees() {
        const operatorId = this.ruleForm.undertaker || this.detail.undertaker; // 选择商户sessionGetItem('accountId') || '8';
        const stationTreeRequest = treeUtils.operatorStations({
          idList: [Number(operatorId || '0')],
        });
        const stationGroupTreeRequest = treeUtils.operatorStationGroup({ operatorId });
        const userTreeRequest = treeUtils.operatorUsers({ operatorId });
        const userTreeGroupRequest = treeUtils.operatorUserGroup({ operatorId });
        const requests = [
          stationTreeRequest,
          stationGroupTreeRequest,
          userTreeRequest,
          userTreeGroupRequest,
        ];
        Promise.all(requests).then(([stationData, stationGroup, usersData, userGroup]) => {
          this.formatStationTreeData(stationData);
          this.formatStationGroupTreeData(stationGroup);
          this.formatUserTreeData(usersData);
          this.formatUserGroupTreeData(userGroup);
          // 选择的是默认
          this.selectedTreesNodes(); // 填充选择内容
        });
      },
      /**
       * 填充树内容
       */
      selectedTreesNodes() {
        if (this.detail?.undertaker && !this.ruleForm.undertaker) {
          // 第一次加载的时候勾选默认
          this.ruleForm.undertaker = this.detail.undertaker;
          // 电站
          this.ruleForm.stationList = this.detail.stationIds;
          if (this.detail?.stationIds?.length > 0) {
            this.$nextTick(() => {
              const selectedStationTree = this.$refs.treeStation;
              selectedStationTree.setCheckedKeys(this.detail?.stationIds, true);
            });
          }

          // 用户
          this.ruleForm.userList = this.detail.userIds;
          if (this.detail?.userIds?.length > 0) {
            this.$refs.treeUser.setCheckedKeys(this.detail?.userIds, true);
          }
          if (this.detail?.userIds?.length > 0) {
            this.$nextTick(() => {
              const selectedUserTree = this.$refs.treeUser;
              selectedUserTree.setCheckedKeys(this.detail?.userIds, true);
            });
          }
        }
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        merchantPriceActivity.detail({ activityId: this.id }).then(res => {
          this.detail = res || {};
          this.fillInitData();
        });
      },
      weeksChange(e) {
        console.log(`选择value为${e.join()}`);
      },

      /**
       * 电费折扣
       */
      checkElectricityDiscount(rule, value, callback) {
        const { electricityDiscount } = this.ruleForm;
        if (this.ruleForm?.powerRateType === '2') {
          if (electricityDiscount > 100 || electricityDiscount < 0) {
            callback(new Error('请输入正确的电费折扣（0.00~100.00）'));
          } else {
            callback();
          }
        } else {
          // 一口价
          callback();
        }
      },
      /**
       * 服务费折扣
       */
      checkServiceDiscount(rule, value, callback) {
        const { serviceDiscount } = this.ruleForm;
        if (this.ruleForm?.serviceRateType === '2') {
          if (serviceDiscount > 100 || serviceDiscount < 0) {
            callback(new Error('请输入正确的服务费折扣（0.00~100.00）'));
          } else {
            callback();
          }
        } else {
          callback();
        }
      },

      filterByIdList,
      /**
       * 图片上传
       */
      uploadImg() {},
      /** 导入电站 */
      stationUpload(file, fileList, isCheck) {
        if (isCheck) {
          orgMatchPushRule
            .station_upload({ file: file.raw })
            .then(res => {
              // console.log('res---站点导入', res);
              this.formData.configIds = res.stationIds;
            })
            .catch(() => {
              this.$refs.stationUpload.fileList = [];
            });
          this.$refs.ruleForm1.validateField('stationUpload');
        }
      },
      /**
       * 清除文件
       */
      handleRemove(propName) {
        this.ruleForm[propName] = [];
      },
      /**
       * 获取各种树
       */
      getBaseInfo() {
        const operatorsRequest = operatorData.userOperatorList({ name: '' }); // 商户列表
        const requests = [operatorsRequest];
        Promise.all(requests).then(([operatorsRes]) => {
          const operators = operatorsRes || [];
          operators.forEach(item => {
            item.operatorId = `${item.operatorId}`;
          });
          this.undertakerList = operators;
          if (this.id) {
            this.getDetail();
          }
        });
      },
      // 把数据转为标准的树形结构
      formatStationTreeData(data) {
        this.stationData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const station = {
              id: `${item.operatorId}`,
              name: item.operatorName || '未知',
              subNodes: [],
              disabled: false,
            };
            if (item?.stationTreeVOList?.length > 0) {
              item.stationTreeVOList.forEach(item1 => {
                const subNode = {
                  id: `${item1.stationId}`,
                  name: item1.stationName,
                  disabled: false,
                };
                station.subNodes.push(subNode);
              });
            }
            this.stationData.push(station);
          });
        }
      },
      formatStationGroupTreeData(data) {
        this.stationGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const stationGroup = {
              id: `${item.id}`,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.stationGroupData.push(stationGroup);
          });
        }
      },
      formatUserTreeData(data) {
        if (data) {
          this.usersData = [];
          let index = 0;
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const key in data) {
            index++;
            const user = {
              id: `${index}`, // item.operatorId,
              name: key,
              subNodes: [],
              disabled: false,
            };
            const privateUsers = data[key];
            privateUsers.forEach(item => {
              user.subNodes.push({
                id: `${item.id}`,
                name: item.name,
              });
            });
            this.usersData.push(user);
          }
        }
      },
      formatUserGroupTreeData(data) {
        this.userGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const userGroup = {
              id: `${item.id}`,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.userGroupData.push(userGroup);
          });
        }
      },
      /**
       * 切换选择方式
       */
      handleChangeStationScopeType(e) {
        // 切换选择方式，重置筛选项
        this.ruleForm.appSee = null;
        this.ruleForm.operatorAttribute = null;
        this.ruleForm.billAttr = null;
        if (e === '1') {
          this.$refs.ruleForm1.clearValidate('operatorStationList');
          // 清空值
          this.ruleForm.operatorStationList = [];
        } else if (e === '2') {
          this.$refs.ruleForm1.clearValidate('stationList');
          // 清空值
          this.ruleForm.stationList = [];
        } else if (e === '4') {
          this.$refs.ruleForm1.clearValidate('cityStationList');
          // 清空值
          this.ruleForm.cityStationList = [];
        }
        // 获取树
        this.getTreeData();
      },
      /**
       * 切换电桩状态、APP可见性、运营商属性、运营商开票属性
       */
      handleChangeTree(e) {
        console.log(e, this.ruleForm.stationScopeType, 'e stationScopeType---handleChangeTree');
        if (this.ruleForm.stationScopeType === '1') {
          // 清空值
          this.ruleForm.operatorStationList = [];
          setTimeout(() => {
            this.$refs.ruleForm1.clearValidate('operatorStationList');
          }, 50);
        } else if (this.ruleForm.stationScopeType === '2') {
          // 清空值
          this.ruleForm.stationList = [];
          setTimeout(() => {
            this.$refs.ruleForm1.clearValidate('stationList');
          }, 50);
        } else if (this.ruleForm.stationScopeType === '4') {
          // 清空值
          this.ruleForm.cityStationList = [];
          setTimeout(() => {
            this.$refs.ruleForm1.clearValidate('cityStationList');
          }, 50);
        }
        // 获取树
        this.getTreeData();
      },
      /**
       * 按城市
       */
      handleCityTree() {
        // 只传子节点
        this.ruleForm.cityStationList = this.$refs.tree3.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.cityStationList', this.ruleForm.cityStationList);
      },
      /**
       * 按电站
       */
      handleStationTree() {
        this.ruleForm.stationList = this.$refs.treeStation.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      /**
       * 电站分组树
       */
      handleStationGroupTree() {
        this.ruleForm.stationGroupList = this.$refs.treeStationGroup.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleUsersTree() {
        this.ruleForm.userList = this.$refs.treeUser.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      /**
       * 按运营商
       */
      handleOperatorTree() {
        // 只传子节点
        this.ruleForm.operatorStationList = this.$refs.tree1.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.operatorStationList', this.ruleForm.operatorStationList);
      },
      /**
       * 活动名称唯一性校验
       */
      onlyCheckRuleName(rule, value, callback) {
        if (value) {
          if (value === this.originActivityName) {
            // 没有修改名称
            this.nameValidError = '';
            callback();
          }
          merchantPriceActivity
            .checkOnlyActivityName({ activityName: value })
            .then(res => {
              if (res?.repeatFlag) {
                // 重复
                // 校验未通过
                this.nameValidError = '活动名称已存在'; // res.resultDesc;
                callback(new Error(this.nameValidError));
              } else if (res?.repeatFlag === false) {
                // 校验通过
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
      /**
       * @desc 获取机构下拉列表
       * */
      doFetchOrgInfos() {
        orgMatchPushRule
          .getFleets({})
          .then(response => {
            this.organizations = response.body;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id || this.editModel === 'copy') {
              merchantPriceActivity
                .add(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              merchantPriceActivity.edit(this.formData).then(res => {
                console.log(res);
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          // resolve(); // 临时跳转下一步
          console.log('监测', `ruleForm${this.active}`);
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            console.log('校验', this.active);
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      /**
       * 初始化form数据
       */
      fillInitData() {
        if (this.editModel === 'edit') {
          this.ruleForm.activityName = this.detail.activityName;
          // this.ruleForm.undertaker = this.detail.undertaker;
          this.ruleForm.activityDate = [];
          if (this.detail.startTime) {
            this.ruleForm.activityDate.push(this.detail.startTime);
          }
          if (this.detail.endTime) {
            this.ruleForm.activityDate.push(this.detail.endTime);
          }
          this.originActivityName = this.detail.activityName;
        }
        this.ruleForm.remarks = this.detail.remarks || '';
        this.ruleForm.selectWeeks = this.detail.activityWeek
          ? this.detail.activityWeek.split(',')
          : [];
        this.ruleForm.electricityDiscount = this.detail.powerRate;
        this.ruleForm.powerRateType = this.detail.powerRateType;
        this.ruleForm.serviceRateType = this.detail.serviceRateType;
        this.ruleForm.serviceDiscount = this.detail.serviceRate;
        if (this.id) {
          this.updateTrees();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  ._line {
    display: flex;

    & > * {
      margin-right: 5px;
    }
  }
</style>
