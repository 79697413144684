<!-- 营销中心 商户营销工具 商户卡券管理 抵用卡管理 新增 -->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="抵用卡名称" prop="cardCouponName">
      <ykc-input
        v-model="ruleForm.cardCouponName"
        placeholder="请输入抵用卡名称"
        maxlength="50"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="承担商户" prop="undertakerId">
      <ykc-dropdown
        placeholder="请输入商户名称"
        :configSet="configSet"
        :data="undertakerList"
        remote
        v-model="ruleForm.undertakerId"
        @change="changeUndertakerId"></ykc-dropdown>
    </ykc-form-item>
    <ykc-form-item label="电费优惠" prop="powerPercentage">
      <ykc-input v-model="ruleForm.powerPercentage" maxlength="3" placeholder="请输入电费优惠">
        <template slot="prepend">折扣比例</template>
        <template slot="append">%</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="服务费优惠" prop="servicePercentage">
      <ykc-input v-model="ruleForm.servicePercentage" maxlength="3" placeholder="请输入服务费优惠">
        <template slot="prepend">折扣比例</template>
        <template slot="append">%</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="抵用卡面额" prop="faceValue">
      <ykc-input v-model="ruleForm.faceValue" placeholder="请输入抵用卡面额" maxlength="6">
        <template slot="append">元</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="抵用卡数量" prop="stockNum">
      <ykc-input v-model="ruleForm.stockNum" placeholder="请输入抵用卡数量" maxlength="8">
        <template slot="append">张</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="有效时间类型" prop="effectiveTimeType">
      <ykc-radio :data="getDic.use_time_type" v-model="ruleForm.effectiveTimeType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item
      label="有效时间"
      prop="afterReceiveDay"
      v-if="ruleForm.effectiveTimeType === '1'">
      <ykc-input v-model="ruleForm.afterReceiveDay" placeholder="请输入相对时间" maxlength="3">
        <template slot="prepend">领取后</template>
        <template slot="append">天</template>
      </ykc-input>
    </ykc-form-item>
    <ykc-form-item label="有效时间" prop="termDate" v-else>
      <ykc-date-picker
        limitDate="limitBefore"
        format="yyyy-MM-dd HH:mm"
        v-model="ruleForm.termDate"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item label="使用说明" prop="useInstructions">
      <ykc-input
        v-model="ruleForm.useInstructions"
        type="textarea"
        placeholder="请输入抵用卡使用说明"
        maxlength="500"></ykc-input>
    </ykc-form-item>
    <template v-if="ruleForm.undertakerId">
      <ykc-form-item prop="stationIdList" label="选择电站">
        <ykc-tree
          v-model="checkAll"
          ref="operaTree"
          :data="treeData"
          :props="treeProps"
          :nodeKey="treeNodeKey"
          @check-change="handleTreeCheckChange"></ykc-tree>
      </ykc-form-item>
    </template>
  </ykc-form>
</template>
<script>
  import { marketManage, barrierPage, operatorData } from '@/service/apis';
  import { sessionGetItem } from '@/utils';
  import regexpObj from '@/utils/regexp';

  export default {
    name: 'CreditCardManageAdd',
    data() {
      return {
        availableList: [
          {
            id: '5',
            name: '按电站选择',
          },
          {
            id: '3',
            name: '按电站分组',
          },
        ],
        checkAll: 'true',
        undertakerList: [],
        configSet: {
          label: 'name',
          value: 'operatorId',
        },
        ruleForm: {
          servicePreferentialWay: '1',
          powerPreferentialWay: '1',
          scopeType: '5',
          cardCouponName: '', // 抵用卡名称
          undertakerId: '',
          stockNum: '', // 总数
          deductionType: '1', // 抵用卡类型 1服务费 2电费 3总电费 字典deduction_type
          faceValue: '', // 优惠金额/折扣比
          amountLimit: '', // 使用条件
          effectiveTimeType: '1', // 时间类型 use_time_type
          useInstructions: '', // 使用说明
          afterReceiveDay: '', // 使用时间几天后到期
          effectiveStartDate: '', // 期限开始时间
          effectiveEndDate: '', // 期限结束时间
          termDate: [],
          stationIdList: [],
        },
        rules: {
          cardCouponName: [
            { required: true, message: '请输入抵用卡名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.nameType.errorTips.error('抵用卡名称'),
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          undertakerId: [{ required: true, message: '请输入商户名称', trigger: 'blur' }],
          powerPercentage: [
            {
              required: true,
              message: '请输入电费优惠',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.percentNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.percentNumberType.errorTips.error('电费优惠', 100),
            },
          ],
          servicePercentage: [
            {
              required: true,
              message: '请输入服务费优惠',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.percentNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.percentNumberType.errorTips.error('服务费优惠', 100),
            },
          ],

          faceValue: [
            { required: true, message: '请输入优惠券面额', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              trigger: 'blur',
              message: '请输入正确的优惠券面额（范围：0.01~999.99）',
            },
            {
              validator: (rule, value, callback) => {
                if (
                  this.ruleForm.amountLimit !== '' &&
                  parseInt(value, 10) > parseInt(this.ruleForm.amountLimit, 10)
                ) {
                  callback('面额需小于等于费用抵扣条件');
                } else {
                  this.$refs.ruleForm.clearValidate('faceValue');
                  callback();
                }
              },
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                console.log(value);
                if (Number(value) > 999.99 || Number(value) < 0.01) {
                  callback('请输入正确的优惠券面额（范围：0.01~999.99）');
                } else {
                  this.$refs.ruleForm.clearValidate('faceValue');
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          stockNum: [
            { required: true, message: '请输入抵用卡数量', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '抵用卡数量',
                99999999
              ),
            },
          ],
          deductionType: [{ required: true, message: '请选择费用抵扣类型', trigger: 'change' }],
          amountLimit: [
            { required: true, message: '请输入费用抵扣条件', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              trigger: 'blur',
              message: '请输入正确的费用抵扣条件（范围：0.01~999.99）',
            },
            {
              validator: (rule, value, callback) => {
                if (
                  this.ruleForm.faceValue !== '' &&
                  parseInt(value, 10) < parseInt(this.ruleForm.faceValue, 10)
                ) {
                  callback('费用抵扣条件需大于等于优惠券面额');
                } else {
                  this.$refs.ruleForm.clearValidate('amountLimit');
                  callback();
                }
              },
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                console.log(value);
                if (Number(value) > 999.99 || Number(value) < 0.01) {
                  callback('请输入正确的费用抵扣条件（范围：0.01~999.99）');
                } else {
                  this.$refs.ruleForm.clearValidate('amountLimit');
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          effectiveTimeType: [{ required: true, message: '请选择有效时间类型', trigger: 'change' }],
          afterReceiveDay: [
            { required: true, message: '请输入相对时间', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.integerNumberType.errorTips.error('相对时间', 999),
            },
          ],
          termDate: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          useInstructions: [
            { required: false, trigger: 'blur', message: '请输入抵用卡使用说明' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.textAreaType.errorTips.error('抵用卡使用说明'),
            },
          ],
          stationIdList: [{ required: true, message: '请选择电站', trigger: 'change' }],
        },
        treeData: [],
        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        treeNodeKey: 'id',
        getDic: {
          deduction_type: [
            { id: '1', name: '服务费' },
            { id: '2', name: '电费' },
            { id: '3', name: '总费用' },
          ],
          use_time_type: [
            { id: '1', name: '相对时间' },
            { id: '2', name: '绝对时间' },
          ],
        },
      };
    },
    created() {
      // this.getCouponAndTree();
      this.getUserOperatorList();
    },
    methods: {
      // 改变承担商户
      changeUndertakerId(val) {
        if (val) {
          this.getCouponAndTree();
        } else {
          this.treeData = [];
        }
      },
      getUserOperatorList() {
        operatorData.userOperatorList({ name: '' }).then(res => {
          this.undertakerList = res || [];
        });
      },
      handleTreeCheckChange() {
        this.ruleForm.stationIdList = this.$refs.operaTree.getCheckedKeys(true);
      },
      /**
       * 获取抵用卡
       */
      getCouponAndTree() {
        // if (this.ruleForm.scopeType === '5') {
        // 按电站选择
        barrierPage
          .findStationList({
            stationOperatorId: this.ruleForm.undertakerId,
          })
          .then(res => {
            this.treeProps = {
              label: 'stationName',
              children: 'subNotes',
              disabled: 'disabled',
            };
            this.treeNodeKey = 'stationId';
            this.treeData = res || [];
          })
          .catch(() => {
            this.treeData = [];
          });
        // } else {
        //   // 按电站分组
        //   treeUtils
        //     .operatorStationGroup({
        //       operatorId: this.ruleForm.undertakerId,
        //     })
        //     .then(res => {
        //       this.treeProps = {
        //         label: 'groupName',
        //         children: 'subNotes',
        //         disabled: 'disabled',
        //       };
        //       this.treeNodeKey = 'id';
        //       this.treeData = res || [];
        //     })
        //     .catch(() => {
        //       this.treeData = [];
        //     });
        // }
      },
      /**
       * 保存操作
       */
      // eslint-disable-next-line no-unused-vars
      async beforeDrawer(cb) {
        if (this.ruleForm.termDate.length === 2) {
          this.ruleForm.effectiveStartDate = `${this.ruleForm.termDate[0]}`;
          this.ruleForm.effectiveEndDate = `${this.ruleForm.termDate[1]}`;
        }
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const reqParams = {
              ...this.ruleForm,
              undertakerType: '2',
              cardCouponType: '1',
              useType: '1',
              operatorBy: sessionGetItem('accountNo'),
            };
            console.log(reqParams);
            marketManage.add(reqParams).then(() => {
              cb && cb();
            });
          }
        });
      },
      /**
       * 活动名称校验
       */
      onlyCheckName(rule, value, callback) {
        marketManage
          .unique({
            cardCouponName: value,
            undertakerType: '2',
            cardCouponType: '1',
          })
          .then(() => {
            callback();
          })
          .catch(err => {
            callback(err.data?.resultDesc);
          });
      },
    },
  };
</script>
<style lang="scss">
  .has-time.ykc-date-picker,
  .has-time.hourHide {
    .el-picker-panel__footer .el-button.el-button--text {
      display: none;
    }
  }
  .el-select-dropdown__list {
    min-width: 94px;
    // font-size: 12px;
    // color: #000;
  }
  .couponValue {
    display: flex;
    justify-content: space-between;
  }
</style>
