<!-- 营销中心 商户营销工具 商户卡券管理 抵用卡管理 增加库存 -->
<template>
  <div class="desc-txt">
    <div class="desc-value">
      <span class="name">{{ name }}</span>
      <span class="val">{{ val }}</span>
    </div>
    <div class="desc-value">
      <span class="name">剩余数量:</span>
      <span class="num">{{ num }}</span>
    </div>
    <div class="tip">温馨提示：所有未发放的卡券将会全部作废，实际结果以点击确认时为准</div>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: '',
      },
      val: {
        type: String,
        default: '',
      },
      num: {
        type: String,
        default: '',
      },
    },
    data() {
      return {};
    },
  };
</script>

<style scoped lang="scss">
  .desc-txt {
    .desc-value {
      margin-bottom: 4px;
    }
    .tip {
      margin-top: 17px;
      color: #d0021b;
      background-color: #fff8ee;
    }
  }

  .name {
    display: inline-block;
    width: 65px;
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
    text-align: right;
    margin-right: 8px;
  }
  .val {
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
  }
</style>
